.SurveyFinisher {
  @apply h-full w-full flex flex-col;
  @apply lg:flex-row;

  .SurveyFinisher__text {
    @apply px-8 py-14 flex-grow;
    @apply lg:px-16 lg:py-28 lg:w-2/3;

    ul {
      @apply list-disc list-inside;
    }

    > * {
      @apply mb-8;
    }
  }

  .SurveyFinisher__action {
    @apply bg-ascent flex flex-col justify-evenly py-16 text-center items-center;
    @apply lg:py-40 lg:w-1/3;

    > img {
      @apply w-80 flex-grow mb-4;
    }

    > p {
      @apply mx-4 max-w-md;
    }

    button {
      @apply mt-8 w-full w-52;
    }
  }
}
