.About {
  @apply h-full w-full flex flex-col;
  @apply lg:flex-row;

  .about-text {
    @apply p-8 flex-grow overflow-y-auto;
    @apply lg:p-16 lg:w-2/3;

    > * {
      @apply mb-8;
    }
  }

  .about-action {
    @apply bg-ascent flex flex-col justify-evenly py-8 text-center items-center;
    @apply lg:py-40 lg:w-1/3;

    img {
      @apply w-80 flex-grow mb-4;
    }

    button {
      @apply mt-8 w-full w-52;
    }
  }
}
