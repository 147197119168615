.Button {
  border-radius: 40px;
  flex-grow: 1;
  min-height: 70px;
  padding: 0 31px;
  -webkit-tap-highlight-color: transparent;
  @apply lg:grow-0;

  // Sizes
  &--xs {
    @apply px-2.5 py-1 text-xs;
  }

  &--sm {
    @apply px-3 py-1.5 text-sm;
  }

  &--md {
    @apply px-5 py-2 text-sm;
  }

  &--lg {
    @apply px-5 py-2 text-base;
  }

  &--xl {
    @apply px-6 py-3 text-base;
  }

  &:disabled {
    @apply text-neutral-400 bg-neutral-100 cursor-not-allowed;
  }

  &:focus {
    @apply ring-2 ring-offset-2;
  }

  // Colors
  &:not(:disabled) {
    &.Button--neutral {
      @apply text-neutral-600-contrast bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-700 ring-neutral-500;
    }

    &.Button--primary {
      @apply text-primary-600-contrast bg-primary-500 hover:bg-primary-600 active:bg-primary-700 ring-primary-500;
    }

    &.Button--success {
      @apply text-success-600-contrast bg-success-500 hover:bg-success-600 active:bg-success-700 ring-success-500;
    }

    &.Button--danger {
      @apply text-danger-600-contrast bg-danger-500 hover:bg-danger-600 active:bg-danger-700 ring-danger-500;
    }

    &.Button--warning {
      @apply text-warning-600-contrast bg-warning-500 hover:bg-warning-600 active:bg-warning-700 ring-warning-500;
    }

    &.Button--info {
      @apply text-info-600-contrast bg-info-500 hover:bg-info-600 active:bg-info-700 ring-info-500;
    }

    // Non-global variants
    &.Button--basic {
      @apply bg-primary;
      @apply text-white;
    }

    &.Button--secondary {
      @apply bg-ascentDark;
    }
  }
}
