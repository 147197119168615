.RadioButton {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 80px;
  padding: 15px 0;
  width: 100%;
  cursor: pointer;

  @apply bg-ascent;

  &:has(&__input:checked) {
    @apply bg-ascentDark;
  }

  &__input {
    @apply accent-selection;
    flex-shrink: 0;
    height: 30px;
    margin: 0 25px;
    width: 30px;
  }

  &__text {
    flex-grow: 1;
    padding-right: 25px;
  }
}
