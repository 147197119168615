.radio-option {
  @apply my-4;
  @apply lg:my-2;
}

.slider-range-label {
  @apply flex flex-row justify-between;
}

.timepicker {
  @apply text-center w-full;
  @apply lg:text-start;

  > div {
    border-radius: 10px;
    height: 80px;
    @apply border-2 border-selection;

    > div {
      padding: 20px;

      input {
        @apply outline-none w-20;
      }
    }
  }
}

.descriptive-question {
  font-family: "Lora";
  font-size: 20px;
  font-weight: 500;
  @media (min-width: 1024px) {
    font-size: 22px;
  }
}
