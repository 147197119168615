.SurveyStepHeader {
  @apply bg-ascent lg:w-1/3 flex flex-col;

  .SurveyStepHeader--container {
    @apply p-8 flex-grow;
    @apply lg:py-0 lg:px-16 flex-grow;

    .question-title {
      flex-grow: 2;
      @apply flex flex-col h-full justify-center;
      font-family: "Lora";
      font-size: 20px;
      font-weight: 500;

      .section-header {
        font-family: "Varela Round";
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        @apply mb-2;
      }

      @media (min-width: 1024px) {
        font-size: 22px;
      }
    }
  }
}
