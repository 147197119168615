.Bullet {
  @apply flex flex-row my-4 h-5;

  &--container {
    @apply w-8 place-content-center flex;
    flex-shrink: 0;

    &.isFinished img {
      position: absolute;
      left: 7px;
    }
  }
  /* vertical lines */
  &::before {
    content: "";
    position: relative;
    left: 17px;
    width: 1px;
    z-index: -1;
  }

  & + &::before {
    top: -22px;
    height: 31px;
    border-left: 1px dashed #dbd9e3;
  }

  &.isFinished + &::before {
    border-left: 2px solid #4b4374;
    left: 16.5px;
    z-index: 2;
  }
}
