.survey-progress-indicator {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progress-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step-chunk {
  @apply bg-ascentDark;
  flex: 1;
  height: 12px;
}

.completed {
  @apply bg-selection;
}
