.backdrop {
  @apply fixed inset-0 flex transition-colors overflow-auto;
}

.modal {
  @apply flex w-[500px] origin-top flex-col rounded-xl bg-white shadow transition-transform m-auto;
}

.btn-close {
  @apply p-2 rounded-lg bg-white;
}

.modal-header {
  @apply flex w-full flex-row items-center justify-between border-b border-b-neutral-300 p-4;

  h2 {
    @apply text-neutral-700;
  }
}

.modal-body {
  padding-top: 2.25rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}

.link-list {
  @apply divide-y divide-neutral-300;

  a {
    @apply text-[#625948] w-full block p-3;
  }

  .link-list-element {
    @apply bg-[#FDF8F0] hover:bg-[#E4DFD8];
  }
}
