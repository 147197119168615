@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url("fonts/inter-100.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: url("fonts/inter-200.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("fonts/inter-300.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("fonts/inter-400.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("fonts/inter-500.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("fonts/inter-600.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("fonts/inter-700.ttf");
}

@font-face {
  font-family: Lora;
  src: url("fonts/lora-regular.ttf");
}

@font-face {
  font-family: "Varela Round";
  src: url("fonts/varela-round-regular.ttf");
}
