.timepicker {
  @apply text-center w-full;
  @apply lg:text-start;

  &.react-time-picker {
    > div {
      border: 2px solid rgba(75, 67, 116, 0.2);
      border-radius: 10px;
      height: 80px;

      > div {
        padding: 20px;
      }

      > div input {
        @apply outline-none w-20;
      }
    }

    &.react-time-picker--open > div {
      @apply border-primary opacity-100;
    }

    &:not(.react-time-picker--open):has(
        input.react-time-picker__inputGroup__hour:valid
      ):has(input.react-time-picker__inputGroup__minute:valid)
      > div {
      @apply border-selection opacity-100;
    }
  }
}

.timepicker__checkmark {
  @apply absolute -top-1 -right-1 hidden;
}

.timepicker.react-time-picker--open:has(
    input.react-time-picker__inputGroup__hour:valid
  ):has(input.react-time-picker__inputGroup__minute:valid)
  ~ .timepicker__checkmark-focused {
  @apply block;
}

.timepicker:not(.react-time-picker--open).react-time-picker:has(
    input.react-time-picker__inputGroup__hour:valid
  ):has(input.react-time-picker__inputGroup__minute:valid)
  ~ .timepicker__checkmark-valid {
  @apply block;
}
