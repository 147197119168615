.About {
  .custom-action {
    min-width: 400px;
    @apply bg-ascent flex flex-col justify-evenly py-4 text-center items-center;
    @apply lg:py-40 lg:w-1/3;

    .button-group {
      @apply flex gap-4 pt-4 w-3/4;

      button {
        @apply w-full;
        @apply lg:mt-8;
      }
    }
  }
}
