.slider-range-label {
  @apply flex flex-row justify-between;
}

span.MuiSlider-root {
  color: #867eaf;
  margin: 0;

  &.slider-valid {
    color: #4b4374;
  }

  .MuiSlider-markLabel {
    top: -10px;
  }

  .MuiSlider-valueLabel {
    @apply bg-selection;
  }

  .MuiSlider-track {
    height: 10px;
  }
}
