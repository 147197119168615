.signup {
  #Input__container {
    @apply m-2;

    .Input {
      height: 40px;
    }
  }
}

.floating-label {
  @apply flex flex-row absolute left-5 top-1 text-light text-sm transition-all;
  @apply peer-placeholder-shown:text-base peer-placeholder-shown:text-neutral peer-placeholder-shown:top-4;
  @apply peer-focus:top-1 peer-focus:text-light peer-focus:text-sm;

  img {
    @apply w-3 opacity-20;
    @apply peer-placeholder-shown:w-4 peer-placeholder-shown:opacity-100;
    @apply peer-focus:w-3 peer-focus:opacity-20;
  }
}
